<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout wrap justify-center row fill-height>
        <v-flex xs12>
          <AppTitle title="Listado de problemas ingresados" :subtitle="`En total ${items.length} problemas ingresados`">
            <template slot="button">
              <v-btn-toggle v-model="statusSelected">
                <v-btn color="warning" class="white--text" :value="0">
                  <span>Pendiente</span>
                  <v-icon>airline_seat_flat</v-icon>
                </v-btn>
                <v-btn color="info" class="white--text" :value="1">
                  <span>Trabajando</span>
                  <v-icon>free_breakfast</v-icon>
                </v-btn>
                <v-btn color="success" class="white--text" :value="2">
                  <span>Listo</span>
                  <v-icon>done</v-icon>
                </v-btn>
                <v-btn color="primary" class="white--text" :value="4">
                  <span>Revisado</span>
                  <v-icon>done_all</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </AppTitle>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-data-table
              :headers="headers"
              :no-data-text="'No hay problemas ingresados'"
              :items="items"
              :loading="loading"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <tr @click="openDialog(props.item)">
                  <td>#{{ props.item.bug }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>
                    <p class="mb-1">Ingreso: {{ $moment(props.item.date_add).format('DD-MM-YYYY HH:mm:ss') }}</p>
                    <p v-if="props.item.status === 2 || props.item.status === 4" class="mb-1">
                      Corregida: {{ $moment(props.item.date_end).format('DD-MM-YYYY HH:mm:ss') }}
                    </p>
                    <p v-if="props.item.status === 4" class="mb-1">
                      Revisada: {{ $moment(props.item.date_check).format('DD-MM-YYYY HH:mm:ss') }}
                    </p>
                  </td>
                  <td>{{ props.item.subject }}</td>
                  <td>
                    <v-chip v-if="props.item.status === 0" color="warning" text-color="white">
                      <v-icon left>airline_seat_flat</v-icon>
                      Pendiente
                    </v-chip>
                    <v-chip v-if="props.item.status === 1" color="info" text-color="white">
                      <v-icon left>free_breakfast</v-icon>
                      Trabajando
                    </v-chip>
                    <v-chip v-if="props.item.status === 2" color="success" text-color="white">
                      <v-icon left>done</v-icon>
                      Listo
                    </v-chip>
                    <v-chip v-if="props.item.status === 3" color="error" text-color="white">
                      <v-icon left>error</v-icon>
                      Listo
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
      <v-btn fixed dark fab bottom right color="secondary" @click="newBug = true">
        <v-icon>add</v-icon>
      </v-btn>
      <v-dialog v-model="showBug" width="600">
        <v-card>
          <AppTitleDialog :title="`Problema #${bug.bug}`">
            <template slot="icons">
              <v-chip label color="info" text-color="white" small>
                <v-icon left>label</v-icon> {{ typeBug(bug) }}
              </v-chip>
              <v-chip v-if="bug.status === 0" small color="warning" text-color="white">
                <v-icon left>airline_seat_flat</v-icon>
                Pendiente
              </v-chip>
              <v-chip v-if="bug.status === 1" small color="info" text-color="white">
                <v-icon left>free_breakfast</v-icon>
                Trabajando
              </v-chip>
              <v-chip v-if="bug.status === 2" small color="success" text-color="white">
                <v-icon left>done</v-icon>
                Listo
              </v-chip>
              <v-chip v-if="bug.status === 3" small color="error" text-color="white">
                <v-icon left>error</v-icon>
                Listo
              </v-chip>
            </template>
          </AppTitleDialog>
          <v-card-text>
            <v-flex v-if="admin" xs12>
              <v-text-field v-model="bug.name" label="Usuario" readonly box hide-details class="mb-2"></v-text-field>
            </v-flex>
            <v-text-field v-model="bug.subject" label="Asunto" readonly box hide-details class="mb-2"></v-text-field>
            <v-textarea
              v-model="bug.body"
              auto-grow
              box
              color="deep-purple"
              label="Descripcion"
              rows="1"
              readonly
              hide-details
              class="mb-2"
            ></v-textarea>
            <v-text-field v-if="bug.url !== ''" v-model="bug.url" label="URL" readonly hide-details box></v-text-field>
            <v-layout v-if="photo1 !== '' || photo2 !== ''" row>
              <v-flex xs6>
                <template v-if="photo1 !== ''">
                  <a :href="photo1" target="_blank">
                    <v-img
                      :src="`${photo1}`"
                      :lazy-src="`https://picsum.photos/10/6?image=${6 * 5 + 10}`"
                      aspect-ratio="1"
                    >
                      <template slot="placeholder">
                        <v-layout fill-height align-center justify-center ma-0>
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-layout>
                      </template>
                    </v-img>
                  </a>
                </template>
              </v-flex>
              <v-flex xs6>
                <template v-if="photo2 !== ''">
                  <a :href="photo2" target="_blank">
                    <v-img
                      :src="`${photo2}`"
                      :lazy-src="`https://picsum.photos/10/6?image=${6 * 5 + 10}`"
                      aspect-ratio="1"
                    >
                      <template slot="placeholder">
                        <v-layout fill-height align-center justify-center ma-0>
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-layout>
                      </template>
                    </v-img>
                  </a>
                </template>
              </v-flex>
            </v-layout>
            <template v-if="!admin">
              <div v-if="bug.status === 2" class="pt-1 pb-0 px-0 body-2">
                <v-alert v-if="bug.body_finale !== ''" :value="true" type="success">
                  {{ bug.body_finale }}
                </v-alert>
                <v-checkbox
                  v-model="ready"
                  :label="!ready ? `El cambio no esta correcto` : '¿Que no se encuentra correcto?'"
                ></v-checkbox>
                <v-textarea
                  v-if="ready"
                  v-model="problemBody"
                  auto-grow
                  box
                  label="Aqui explique el problema"
                  rows="1"
                ></v-textarea>
                <v-btn :color="ready ? 'error' : 'success'" block large @click="responseCheck">
                  {{ ready ? 'Actualizar y poner en pendiente' : 'Esta correcto' }}
                </v-btn>
              </div>
              <div v-if="bug.status === 3" class="pt-3 pb-0 px-2 body-2">
                <v-alert :value="true" type="error">
                  {{ bug.body_finale }}
                </v-alert>
              </div>
            </template>
            <template v-else>
              <v-alert
                v-if="bug.problem_body !== undefined && bug.problem_body !== '' && bug.problem_body !== 0"
                :value="true"
                type="error"
              >
                {{ bug.problem_body }}
              </v-alert>
              <v-select
                v-model="bug.status"
                class="mt-2"
                :items="statusItems"
                item-text="name"
                item-value="id"
                label="Estado del pedido"
              ></v-select>
              <v-textarea
                v-model="bug.body_finale"
                auto-grow
                box
                color="deep-purple"
                label="Descripcion"
                rows="1"
              ></v-textarea>
              <v-btn color="success" block large @click="responseProblem"> Actualizar </v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newBug" width="500" persistent>
        <v-card>
          <AppTitleDialog title="Ingresar problema" :close-action="() => (newBug = false)" close> </AppTitleDialog>
          <v-layout class="pa-3" wrap>
            <v-flex xs12>
              <v-subheader>Tipo de problema</v-subheader>
              <v-radio-group v-model="form.type" :mandatory="true" class="ma-0" row small>
                <v-radio label="Error" value="error" small></v-radio>
                <v-radio label="Cambio" value="cambios"></v-radio>
                <v-radio label="Nueva Funcionalidad" value="funcionalidad"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="form.subject" outline label="Asunto"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="form.url"
                outline
                label="Url"
                hint="pegar url donde presenta el problema"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="form.body"
                outline
                class="mb-0"
                label="Descripcion"
                hint="Describir con detalle el problema"
              ></v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-subheader>Fotos (opcional)</v-subheader>
              <v-layout row>
                <v-flex xs6 class="pr-2">
                  <img v-if="form.photo1" :src="form.photo1" style="width: 100%;max-width: 100%;" />
                  <v-btn color="secondary" depressed @click="$refs.file.click()"> Foto 1 </v-btn>
                  <input id="file" ref="file" type="file" style="display: none" @change="handleFileUpload('file')" />
                </v-flex>
                <v-flex xs6 class="pl-2">
                  <img v-if="form.photo2" :src="form.photo2" style="width: 100%;max-width: 100%;" />
                  <v-btn color="secondary" depressed @click="$refs.file2.click()"> Foto 2 </v-btn>
                  <input id="file2" ref="file2" type="file" style="display: none" @change="handleFileUpload('file2')" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="back" flat depressed @click.stop="newBug = false">
              Volver
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat depressed @click="addBug">
              Ingresar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import { firestore, storage } from '../fireStore'
import AppTitle from './useful/title.vue'
import AppTitleDialog from './useful/titleDialog.vue'

export default {
  name: 'HelpAndBug',
  components: { AppTitle, AppTitleDialog },
  metaInfo: {
    title: 'Problemas'
  },
  data() {
    return {
      photo1: '',
      photo2: '',
      problemBody: '',
      form: {
        type: 'error',
        subject: '',
        body: '',
        status: 0,
        statusResponse: 0,
        body_finale: '',
        url: '',
        photo1: '',
        photo2: '',
        file: '',
        file2: ''
      },
      ready: false,
      showBug: false,
      headers: [
        {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'error'
        },
        {
          text: 'Usuario',
          align: 'left',
          sortable: false,
          value: 'user'
        },
        {
          text: 'Fecha',
          align: 'left',
          sortable: false,
          value: 'date_add'
        },
        {
          text: 'Asunto',
          align: 'left',
          sortable: false,
          value: 'subject'
        },
        {
          text: 'Estado',
          align: 'left',
          sortable: false,
          value: 'status'
        }
      ],
      items: [],
      itemsAll: [],
      statusItems: [
        { id: 0, name: 'Pendiente' },
        { id: 1, name: 'Trabajando' },
        { id: 2, name: 'Listo' },
        { id: 3, name: 'Complejo' }
      ],
      loading: false,
      newBug: false,
      bug: {
        photo1: '',
        photo2: ''
      },
      admin: false,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      statusSelected: 0
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    user() {},
    statusSelected() {
      this.filterItems()
    }
  },
  mounted() {
    console.log('Mounted')
    if (this.user.id === 12) {
      this.admin = true
    }
    this.getBugs()
  },
  methods: {
    handleFileUpload(item) {
      // eslint-disable-next-line prefer-destructuring
      this.form[item] = this.$refs[item].files[0]
      if (item === 'file') {
        this.form.photo1 = URL.createObjectURL(this.$refs[item].files[0])
      } else if (item === 'file2') {
        this.form.photo2 = URL.createObjectURL(this.$refs[item].files[0])
      }
    },
    typeBug(e) {
      if (e.type === 'error') {
        return 'Error'
      }
      if (e.type === 'funcionalidad') {
        return 'Nueva Funcionalidad'
      }
      if (e.type === 'cambios') {
        return 'Cambio'
      }
      if (e.type === 'ortograficos') {
        return 'Ortograficos'
      }
      return ''
    },
    addBug() {
      if (this.form.subject !== '' && this.form.body !== '') {
        const userID = this.user.id

        firestore
          .collection('usersActives')
          .doc(`user-${userID}`)
          .collection('bugs')
          .add({
            type: this.form.type,
            subject: this.form.subject,
            body: this.form.body,
            status: this.form.status,
            url: this.form.url,
            name: this.user.name,
            date_add: this.$moment.now(),
            bug: this.items.length + 1
          })
          .then(doc => {
            const docID = doc.id
            if (this.form.file !== '') {
              storage
                .ref()
                .child(`images/${this.form.file.name}`)
                .put(this.form.file)
                .then(snap => {
                  firestore
                    .collection('usersActives')
                    .doc(`user-${userID}`)
                    .collection('bugs')
                    .doc(docID)
                    .update({
                      file: snap.metadata.fullPath
                    })
                })
            }
            if (this.form.file2 !== '') {
              storage
                .ref()
                .child(`images/${this.form.file2.name}`)
                .put(this.form.file2)
                .then(snap => {
                  firestore
                    .collection('usersActives')
                    .doc(`user-${userID}`)
                    .collection('bugs')
                    .doc(docID)
                    .update({
                      file2: snap.metadata.fullPath
                    })
                })
            }
          })
          .catch(err => console.error(err))
        this.newBug = false
        this.getBugs()
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: 'Debe rellenar todos los campos' })
      }
    },
    getBugs() {
      const self = this
      firestore
        .collection('usersActives')
        .orderBy('name', 'desc')
        .get()
        .then(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            const user = doc.data()
            firestore
              .collection('usersActives')
              .doc(`user-${user.id}`)
              .collection('bugs')
              .orderBy('date_add', 'desc')
              .get()
              .then(queryForBugs => {
                queryForBugs.forEach(bug => {
                  const bugItem = bug.data()
                  bugItem.key = bug.id
                  bugItem.userID = `user-${user.id}`
                  items.push(bugItem)
                })
                self.items = items
                self.itemsAll = items
                this.filterItems()
              })
          })
        })
    },
    orderBy() {
      this.items = this._.orderBy(this.items, ['time'], ['desc'])
    },
    openDialog(item) {
      const self = this
      self.bug = item
      self.photo1 = ''
      self.photo2 = ''
      self.showBug = true
      if (self.bug.file !== '') {
        storage
          .ref()
          .child(self.bug.file)
          .getDownloadURL()
          .then(url => {
            self.photo1 = url
          })
      }
      if (self.bug.file2 !== '') {
        storage
          .ref()
          .child(self.bug.file2)
          .getDownloadURL()
          .then(url => {
            self.photo2 = url
          })
      }
    },
    responseProblem() {
      const self = this
      firestore
        .collection('usersActives')
        .doc(this.bug.userID)
        .collection('bugs')
        .doc(this.bug.key)
        .update({
          status: self.bug.status,
          body_finale: self.bug.body_finale === undefined ? '' : self.bug.body_finale,
          date_end: self.bug.status === 2 ? self.$moment.now() : ''
        })
      self.showBug = false
      self.form.subject = ''
      self.form.body = ''
      self.form.statusResponse = 0
      self.form.body_finale = ''
      self.getBugs()
    },
    responseCheck() {
      const self = this
      let error = false
      if (self.ready && self.problemBody === '') {
        error = true
      }
      if (!error) {
        firestore
          .collection('usersActives')
          .doc(this.bug.userID)
          .collection('bugs')
          .doc(this.bug.key)
          .update({
            status: self.ready ? 0 : 4,
            problem_body: self.ready ? self.problemBody : 0,
            date_check: self.ready ? '' : self.$moment.now()
          })
        self.ready = false
        self.showBug = false
        self.problemBody = ''
        self.form.subject = ''
        self.form.body = ''
        self.form.statusResponse = 0
        self.form.body_finale = ''
        self.getBugs()
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: 'Debe explicar su problema con el cambio' })
      }
    },
    filterItems(status) {
      this.statusSelected = status === undefined ? this.statusSelected : status
      this.items = this._.filter(this.itemsAll, ['status', this.statusSelected])
    }
  }
}
</script>

<style></style>
